import "./src/styles/global.css";
import "./src/styles/reset.css";

import type { GatsbyBrowser } from "gatsby";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import * as React from "react";

import { BreakpointProvider } from "./src/contexts/BreakpointContext";
import IntroSectionProvider from "./src/contexts/IntroTextContext";
import ModalProvider from "./src/contexts/ModalContext";
import ModeProvider from "./src/contexts/ModeContext";
import ShareProvider from "./src/contexts/ShareContext";
import ToastProvider from "./src/contexts/ToastContext";

gsap.registerPlugin(ScrollTrigger);

export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] =
  () => {
    if (window.location.pathname === "/") {
      const loader = document.getElementById("___loader");
      setTimeout(() => {
        if (loader) {
          loader.style.display = "none";
        }
      }, 200);
    }
  };

const myCustomQueries = {
  xsmall: "screen and (max-width: 360px)",
  xlsmall: "screen and (max-width: 480px)",
  small: "screen and (max-width: 768px)",
  medium: "screen and (max-width: 992px)",
  large: "screen and (max-width: 1280px)",
  xlarge: "screen and (min-width: 1281px)",
};

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return (
    <ModeProvider>
      <ModalProvider>
        <ShareProvider>
          <IntroSectionProvider>
            <BreakpointProvider queries={myCustomQueries}>
              <ToastProvider>{element}</ToastProvider>
            </BreakpointProvider>
          </IntroSectionProvider>
        </ShareProvider>
      </ModalProvider>
    </ModeProvider>
  );
};
