exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-event-tsx": () => import("./../../../src/pages/event.tsx" /* webpackChunkName: "component---src-pages-event-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-share-normal-index-tsx": () => import("./../../../src/pages/share/normal/index.tsx" /* webpackChunkName: "component---src-pages-share-normal-index-tsx" */),
  "component---src-pages-share-reward-1-index-tsx": () => import("./../../../src/pages/share/reward1/index.tsx" /* webpackChunkName: "component---src-pages-share-reward-1-index-tsx" */),
  "component---src-pages-share-reward-2-index-tsx": () => import("./../../../src/pages/share/reward2/index.tsx" /* webpackChunkName: "component---src-pages-share-reward-2-index-tsx" */),
  "component---src-pages-share-reward-3-index-tsx": () => import("./../../../src/pages/share/reward3/index.tsx" /* webpackChunkName: "component---src-pages-share-reward-3-index-tsx" */),
  "component---src-pages-share-reward-4-index-tsx": () => import("./../../../src/pages/share/reward4/index.tsx" /* webpackChunkName: "component---src-pages-share-reward-4-index-tsx" */),
  "component---src-pages-share-reward-5-index-tsx": () => import("./../../../src/pages/share/reward5/index.tsx" /* webpackChunkName: "component---src-pages-share-reward-5-index-tsx" */)
}

